import { BoxProps } from "@ui/components/Box/Box";
import { ScrollElementContext } from "@ui/contexts/scrollElementContext";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { useClickableProps } from "@uxf/core/hooks/useClickableProps";
import { useMinWindowWidth } from "@uxf/core/hooks/useMinWindowWidth";
import { useOnUnmount } from "@uxf/core/hooks/useOnUnmount";
import { isNil } from "@uxf/core/utils/is-nil";
import { HTMLAttributes, KeyboardEventHandler, MouseEventHandler, ReactNode, useContext, useRef } from "react";

export interface CollapsibleProps {
    analyticsCallback?: () => void;
    children?: ReactNode;
    className?: string;
    classNameFocusColor?: string;
    disableScrollOnExpanded?: boolean;
    isExpanded?: boolean;
    onClick?: MouseEventHandler;
    onKeyDown?: KeyboardEventHandler;
    onKeyUp?: KeyboardEventHandler;
    onToggle?: () => void;
    role?: HTMLAttributes<HTMLDivElement>["role"];
}

export function Collapsible(props: CollapsibleProps) {
    const scrollRef = useRef<HTMLDivElement>(null);
    const [scrollElement] = useContext(ScrollElementContext);
    const isDesktop = useMinWindowWidth(BREAKPOINTS.md);

    const timer = useRef<number>();

    const clickHandler: MouseEventHandler = (e) => {
        props.onClick?.(e);

        props.onToggle?.();

        const node = scrollRef.current;

        if (
            !props.disableScrollOnExpanded &&
            node?.contains(e.currentTarget) &&
            !props.isExpanded &&
            !isDesktop &&
            scrollElement
        ) {
            timer.current = window.setTimeout(() => {
                scrollElement.scrollTo({
                    top: node.offsetTop,
                    left: 0,
                    behavior: "smooth",
                });
            }, 200);
        }
    };

    useOnUnmount(() => clearTimeout(timer.current));

    const clickableProps = useClickableProps<BoxProps>({
        "aria-current": props.isExpanded ? true : undefined,
        "aria-expanded": props.isExpanded,
        analyticsCallback: props.analyticsCallback,
        className: `overflow-hidden cursor-pointer outline-none focus-visible:shadow-focus ${props.classNameFocusColor ?? "[--focus-color:theme(colors.green.DEFAULT/0.25)]"} ${props.className ?? ""}`,
        disabled: isNil(props.onClick || props.onToggle),
        onKeyDown: props.onKeyDown,
        onKeyUp: props.onKeyUp,
        onClick: clickHandler,
        role: props.role,
    });

    return (
        <div ref={scrollRef} {...clickableProps}>
            {props.children}
        </div>
    );
}
