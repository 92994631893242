/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { KnowledgebaseCategoryPreviewFragmentDoc, KnowledgebaseCategoryFragmentDoc, KnowledgebaseQuestionPreviewFragmentDoc, KnowledgebaseQuestionDetailFragmentDoc } from './KnowledgebaseFragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const GetKnowledgebaseRootCategoriesDocument = gql`
    query getKnowledgebaseRootCategories {
  getKnowledgebaseRootCategories {
    ...KnowledgebaseCategoryPreview
  }
}
    ${KnowledgebaseCategoryPreviewFragmentDoc}`;

/**
 * __useGetKnowledgebaseRootCategoriesQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseRootCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseRootCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseRootCategoriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetKnowledgebaseRootCategoriesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetKnowledgebaseRootCategoriesQuery, Types.GetKnowledgebaseRootCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseRootCategoriesQuery, Types.GetKnowledgebaseRootCategoriesQueryVariables>(GetKnowledgebaseRootCategoriesDocument, options);
      }
export function useGetKnowledgebaseRootCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseRootCategoriesQuery, Types.GetKnowledgebaseRootCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseRootCategoriesQuery, Types.GetKnowledgebaseRootCategoriesQueryVariables>(GetKnowledgebaseRootCategoriesDocument, options);
        }
export type GetKnowledgebaseRootCategoriesQueryHookResult = ReturnType<typeof useGetKnowledgebaseRootCategoriesQuery>;
export type GetKnowledgebaseRootCategoriesLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseRootCategoriesLazyQuery>;
export type GetKnowledgebaseRootCategoriesQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseRootCategoriesQuery, Types.GetKnowledgebaseRootCategoriesQueryVariables>;
export const GetKnowledgebaseCategoryDocument = gql`
    query getKnowledgebaseCategory($category: Int!) {
  getKnowledgebaseCategory(category: $category) {
    ...KnowledgebaseCategory
  }
}
    ${KnowledgebaseCategoryFragmentDoc}`;

/**
 * __useGetKnowledgebaseCategoryQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseCategoryQuery({
 *   variables: {
 *      category: // value for 'category'
 *   },
 * });
 */
export function useGetKnowledgebaseCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKnowledgebaseCategoryQuery, Types.GetKnowledgebaseCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseCategoryQuery, Types.GetKnowledgebaseCategoryQueryVariables>(GetKnowledgebaseCategoryDocument, options);
      }
export function useGetKnowledgebaseCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseCategoryQuery, Types.GetKnowledgebaseCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseCategoryQuery, Types.GetKnowledgebaseCategoryQueryVariables>(GetKnowledgebaseCategoryDocument, options);
        }
export type GetKnowledgebaseCategoryQueryHookResult = ReturnType<typeof useGetKnowledgebaseCategoryQuery>;
export type GetKnowledgebaseCategoryLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseCategoryLazyQuery>;
export type GetKnowledgebaseCategoryQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseCategoryQuery, Types.GetKnowledgebaseCategoryQueryVariables>;
export const GetKnowledgebaseQuestionDocument = gql`
    query getKnowledgebaseQuestion($question: Int!) {
  getKnowledgebaseQuestion(question: $question) {
    ...KnowledgebaseQuestionDetail
  }
}
    ${KnowledgebaseQuestionDetailFragmentDoc}`;

/**
 * __useGetKnowledgebaseQuestionQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseQuestionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseQuestionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseQuestionQuery({
 *   variables: {
 *      question: // value for 'question'
 *   },
 * });
 */
export function useGetKnowledgebaseQuestionQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKnowledgebaseQuestionQuery, Types.GetKnowledgebaseQuestionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseQuestionQuery, Types.GetKnowledgebaseQuestionQueryVariables>(GetKnowledgebaseQuestionDocument, options);
      }
export function useGetKnowledgebaseQuestionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseQuestionQuery, Types.GetKnowledgebaseQuestionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseQuestionQuery, Types.GetKnowledgebaseQuestionQueryVariables>(GetKnowledgebaseQuestionDocument, options);
        }
export type GetKnowledgebaseQuestionQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionQuery>;
export type GetKnowledgebaseQuestionLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionLazyQuery>;
export type GetKnowledgebaseQuestionQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseQuestionQuery, Types.GetKnowledgebaseQuestionQueryVariables>;
export const GetKnowledgebaseQuestionFulltextDocument = gql`
    query getKnowledgebaseQuestionFulltext($searchTerm: String!, $type: QuestionType) {
  getKnowledgebaseQuestionFulltext(searchTerm: $searchTerm, type: $type) {
    ...KnowledgebaseQuestionPreview
  }
}
    ${KnowledgebaseQuestionPreviewFragmentDoc}`;

/**
 * __useGetKnowledgebaseQuestionFulltextQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseQuestionFulltextQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseQuestionFulltextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseQuestionFulltextQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetKnowledgebaseQuestionFulltextQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKnowledgebaseQuestionFulltextQuery, Types.GetKnowledgebaseQuestionFulltextQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseQuestionFulltextQuery, Types.GetKnowledgebaseQuestionFulltextQueryVariables>(GetKnowledgebaseQuestionFulltextDocument, options);
      }
export function useGetKnowledgebaseQuestionFulltextLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseQuestionFulltextQuery, Types.GetKnowledgebaseQuestionFulltextQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseQuestionFulltextQuery, Types.GetKnowledgebaseQuestionFulltextQueryVariables>(GetKnowledgebaseQuestionFulltextDocument, options);
        }
export type GetKnowledgebaseQuestionFulltextQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionFulltextQuery>;
export type GetKnowledgebaseQuestionFulltextLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionFulltextLazyQuery>;
export type GetKnowledgebaseQuestionFulltextQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseQuestionFulltextQuery, Types.GetKnowledgebaseQuestionFulltextQueryVariables>;
export const GetKnowledgebaseQuestionsByTagsDocument = gql`
    query getKnowledgebaseQuestionsByTags($data: KnowledgebaseGetByTagsInput!) {
  getKnowledgebaseQuestionsByTags(data: $data) {
    ...KnowledgebaseQuestionPreview
  }
}
    ${KnowledgebaseQuestionPreviewFragmentDoc}`;

/**
 * __useGetKnowledgebaseQuestionsByTagsQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseQuestionsByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseQuestionsByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseQuestionsByTagsQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetKnowledgebaseQuestionsByTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKnowledgebaseQuestionsByTagsQuery, Types.GetKnowledgebaseQuestionsByTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseQuestionsByTagsQuery, Types.GetKnowledgebaseQuestionsByTagsQueryVariables>(GetKnowledgebaseQuestionsByTagsDocument, options);
      }
export function useGetKnowledgebaseQuestionsByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseQuestionsByTagsQuery, Types.GetKnowledgebaseQuestionsByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseQuestionsByTagsQuery, Types.GetKnowledgebaseQuestionsByTagsQueryVariables>(GetKnowledgebaseQuestionsByTagsDocument, options);
        }
export type GetKnowledgebaseQuestionsByTagsQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionsByTagsQuery>;
export type GetKnowledgebaseQuestionsByTagsLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionsByTagsLazyQuery>;
export type GetKnowledgebaseQuestionsByTagsQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseQuestionsByTagsQuery, Types.GetKnowledgebaseQuestionsByTagsQueryVariables>;
export const GetKnowledgebaseQuestionsByTypeDocument = gql`
    query getKnowledgebaseQuestionsByType($data: KnowledgebaseGetByTypeInput!) {
  getKnowledgebaseQuestionsByType(data: $data) {
    ...KnowledgebaseQuestionPreview
  }
}
    ${KnowledgebaseQuestionPreviewFragmentDoc}`;

/**
 * __useGetKnowledgebaseQuestionsByTypeQuery__
 *
 * To run a query within a React component, call `useGetKnowledgebaseQuestionsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetKnowledgebaseQuestionsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetKnowledgebaseQuestionsByTypeQuery({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useGetKnowledgebaseQuestionsByTypeQuery(baseOptions: Apollo.QueryHookOptions<Types.GetKnowledgebaseQuestionsByTypeQuery, Types.GetKnowledgebaseQuestionsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetKnowledgebaseQuestionsByTypeQuery, Types.GetKnowledgebaseQuestionsByTypeQueryVariables>(GetKnowledgebaseQuestionsByTypeDocument, options);
      }
export function useGetKnowledgebaseQuestionsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetKnowledgebaseQuestionsByTypeQuery, Types.GetKnowledgebaseQuestionsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetKnowledgebaseQuestionsByTypeQuery, Types.GetKnowledgebaseQuestionsByTypeQueryVariables>(GetKnowledgebaseQuestionsByTypeDocument, options);
        }
export type GetKnowledgebaseQuestionsByTypeQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionsByTypeQuery>;
export type GetKnowledgebaseQuestionsByTypeLazyQueryHookResult = ReturnType<typeof useGetKnowledgebaseQuestionsByTypeLazyQuery>;
export type GetKnowledgebaseQuestionsByTypeQueryResult = Apollo.QueryResult<Types.GetKnowledgebaseQuestionsByTypeQuery, Types.GetKnowledgebaseQuestionsByTypeQueryVariables>;