import { ArticleFragment, FileFragment, ImageFragment } from "@gql-schema";

const getFileUrl = (file: FileFragment): string => {
    const ch1 = file.uuid.charAt(0);
    const ch2 = file.uuid.charAt(1);

    return `/upload/${file.namespace}/${ch1}/${ch2}/${file.uuid}.${file.extension}`;
};

export type ImageType = "c" | "t" | "in";

enum ResizerTypeEnum {
    "c" = "cv",
    "t" = "cn",
    "in" = "in",
}

const getImageUrl = (
    file?: Pick<FileFragment, "uuid" | "extension" | "namespace" | "type">,
    width = 200,
    height = 300,
    type: ImageType = "c",
): string | null => {
    if (!file || !file.uuid) {
        return null;
    }

    if (file.extension === "svg" || file.type === "image/svg+xml") {
        return getFileUrl(file as FileFragment);
    }

    const ch1 = file.uuid.charAt(0);
    const ch2 = file.uuid.charAt(1);

    const fit = ResizerTypeEnum[type];
    const position = "c";
    const background = "t";
    const trim = "nt";
    const ext = file.extension;
    const toFormat = file.extension;
    const namespace = file.namespace;

    return `/generated/${namespace}/${ch1}/${ch2}/${file.uuid}_${width}_${height}_${fit}_${position}_${background}_${trim}_x_${ext}.${toFormat}`;
};

const getImageHiDpiSrcSet = (
    file?: FileFragment | ImageFragment,
    width = 200,
    height = 300,
    type: ImageType = "c",
): string | null => {
    if (!file || !file.uuid || file.extension === "svg") {
        return null;
    }

    return `${getImageUrl(file, width * 2, height * 2, type)} 2x, ${getImageUrl(file, width * 3, height * 3, type)} 3x`;
};

const getBlogArticleParams = (article: Required<Pick<ArticleFragment, "id" | "seoName">>) => {
    return {
        articleId: article.id,
        slug: article.seoName,
    };
};

const getBlogCategoryParams = (categoryId: number) => {
    return {
        categoryId: categoryId,
    };
};

export const UrlHelper = {
    getImageUrl,
    getImageHiDpiSrcSet,
    getFileUrl,
    getBlogArticleParams,
    getBlogCategoryParams,
};
