import { LinkProps } from "@app-routes";
import { IconLink } from "@ui/components/IconLink/IconLink";
import { COLORS } from "@ui/theme/colors";
import { Property } from "csstype";
import useEmblaCarousel from "embla-carousel-react";
import { Children, FC, PropsWithChildren, useEffect, useState } from "react";

interface Props {
    className?: string;
    carouselClassName?: string;
    closeButtonHref?: string;
    closeButtonRoute?: LinkProps["route"];
    closeButtonRouteParams?: LinkProps["params"];
    controlsBackgroundHoverColor?: Property.BackgroundColor;
    controlsColor?: Property.Color;
    hideDots?: boolean;
    showDisabledControls?: boolean;
}

export const SnapCarousel: FC<PropsWithChildren<Props>> = (props) => {
    const {
        children,
        closeButtonHref,
        closeButtonRoute,
        closeButtonRouteParams,
        controlsBackgroundHoverColor = COLORS.green.dark,
        controlsColor = COLORS.white,
        hideDots,
        showDisabledControls,
    } = props;

    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: false,
    });

    const [emblaState, setEmblaState] = useState<{
        canScrollPrev: boolean;
        canScrollNext: boolean;
        selectedScrollSnap: number;
    }>();

    useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const handler = () =>
            setEmblaState((prev) => ({
                ...prev,
                canScrollNext: emblaApi.canScrollNext(),
                canScrollPrev: emblaApi.canScrollPrev(),
                selectedScrollSnap: emblaApi.selectedScrollSnap(),
            }));

        handler();

        emblaApi.on("select", handler);
        return () => {
            emblaApi.off("select", handler);
        };
    }, [emblaApi]);

    const onPrev = () => emblaApi?.scrollPrev();

    const onNext = () => emblaApi?.scrollNext();

    const onMove = (index: number) => () => emblaApi?.scrollTo(index);

    return (
        <div className={`flex flex-col overflow-hidden ${props.className ?? ""}`}>
            {(closeButtonRoute || closeButtonHref) && (
                <div className="absolute right-6 top-6 z-1">
                    <IconLink
                        $backgroundHoverColor={COLORS.green.dark}
                        $color={COLORS.white}
                        href={closeButtonHref}
                        name="cross"
                        params={closeButtonRouteParams}
                        route={closeButtonRoute}
                        size={24}
                        title="Zavřít"
                    />
                </div>
            )}
            <div className="relative flex min-h-0 grow overflow-visible" ref={emblaRef}>
                <div className={`flex w-screen ${props.carouselClassName ?? ""}`} role="list">
                    {Children.map(children, (component, index) => (
                        <div
                            className="user-select-none flex h-inherit w-screen shrink-0 justify-center p-6 text-white"
                            key={index}
                            role="listitem"
                        >
                            {component}
                        </div>
                    ))}
                </div>
            </div>
            <div className={`flex items-center p-6 ${hideDots ? "justify-center" : "justify-between"}`}>
                <div className={!showDisabledControls && !emblaState?.canScrollPrev ? "invisible" : "visible"}>
                    <IconLink
                        $backgroundHoverColor={controlsBackgroundHoverColor}
                        $color={controlsColor}
                        $mr={hideDots ? 16 : undefined}
                        disabled={showDisabledControls && !emblaState?.canScrollPrev}
                        name="chevronLeftCircleOutline"
                        onClick={onPrev}
                        size={54}
                        title="Zpět"
                    />
                </div>
                {!hideDots && (
                    <div className="flex">
                        {Children.map(children, (component, index) => (
                            // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
                            <div
                                className={`mx-1 size-2 cursor-pointer rounded-full bg-white ${
                                    index === (emblaState?.selectedScrollSnap ?? 0) ? "opacity-100" : "opacity-50"
                                }`}
                                key={index}
                                onClick={onMove(index)}
                            />
                        ))}
                    </div>
                )}
                <div className={!showDisabledControls && !emblaState?.canScrollNext ? "invisible" : "visible"}>
                    <IconLink
                        $backgroundHoverColor={controlsBackgroundHoverColor}
                        $color={controlsColor}
                        $ml={hideDots ? 16 : undefined}
                        disabled={showDisabledControls && !emblaState?.canScrollNext}
                        name="chevronRightCircleOutline"
                        onClick={onNext}
                        size={54}
                        title="Vpřed"
                    />
                </div>
            </div>
        </div>
    );
};
