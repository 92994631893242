import { KnowledgebaseQuestionPreviewFragment } from "@gql-schema";
import { CollapsibleFaqContent } from "@ui/components/Collapsible/CollapsibleFaqContent";
import { slugify } from "@uxf/core/utils/slugify";
import { useMemo } from "react";

export const useMapFaqs = (data: KnowledgebaseQuestionPreviewFragment[], invert = false) =>
    useMemo(
        () =>
            data.map((q: KnowledgebaseQuestionPreviewFragment) => ({
                id: q.id,
                title: q.title,
                text: q.perex && (
                    <CollapsibleFaqContent text={q.perex} faqId={q.id} seoName={q.seoName} invert={invert} />
                ),
                seoName: slugify(q.seoName || ""),
            })),
        [data, invert],
    );
