import { FC } from "react";

// TODO: @KubaH correct types
export const HeadingRenderer: FC<any> = ({ level, children }) => {
    switch (level) {
        case 1:
            return <h1 className="ui-typo-h1 mb-6 mt-11 text-green">{children}</h1>;
        case 2:
            return <h2 className="ui-typo-h2 mb-6 mt-11 text-green">{children}</h2>;
        case 3:
            return <h3 className="ui-typo-h3 mb-5 mt-10 text-green">{children}</h3>;
        case 4:
            return <h4 className="ui-typo-h4 mb-4 mt-9 text-green">{children}</h4>;
        case 5:
            return <h5 className="ui-typo-h5 mb-3.5 mt-6 text-green">{children}</h5>;
        case 6:
        default:
            return <h6 className="ui-typo-h6 mb-3.5 mt-6 text-green">{children}</h6>;
    }
};
