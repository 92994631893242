import { ButtonOpaque } from "@ui/components/Button/ButtonOpaque";
import { ButtonYellow } from "@ui/components/Button/ButtonYellow";
import { CollapsibleFaq } from "@ui/components/Collapsible/collapsible-faq";
import { Icon } from "@ui/components/Icon/Icon";
import { BUTTONS } from "@ui/theme/buttons";
import { Show } from "@uxf/core/components/show";
import { isNil } from "@uxf/core/utils/is-nil";
import { ReactNode, memo, useState, type MouseEventHandler } from "react";

export interface FaqAccordionItemData {
    id: number;
    seoName?: string;
    text: ReactNode;
    title: ReactNode;
}

interface Props {
    className?: string;
    id?: string;
    data?: FaqAccordionItemData[];
    invert?: boolean;
    onClickRedirect?: (item: FaqAccordionItemData) => void;
}

const VISIBLE_QUESTIONS_COUNT = 4;

export const FaqAccordion = memo<Props>((props) => {
    const [activeItemIndex, setActiveItemIndex] = useState("");
    const [showAllQuestions, setShowAllQuestions] = useState(false);

    const handleFaqClick =
        (item: FaqAccordionItemData): MouseEventHandler =>
        () => {
            if (props.onClickRedirect && !item.text) {
                props.onClickRedirect(item);
            }
        };

    const onToggle = (item: FaqAccordionItemData, index: string) => () => {
        if (item.text) {
            if (activeItemIndex !== index) {
                setActiveItemIndex(index);
            }
            if (activeItemIndex === index) {
                setActiveItemIndex("");
            }
        }
    };

    const loadAllHandler: MouseEventHandler = () => setShowAllQuestions(true);

    if (isNil(props.data)) {
        return null;
    }

    const totalQuestionsCount = props.data.length;

    const ShowMoreButtonComponent = props.invert ? ButtonYellow : ButtonOpaque;

    return (
        <div className={props.className} id={props.id}>
            <div className="column-gap-8 md:columns-2" role="list">
                {props.data.map((item, index) => (
                    <CollapsibleFaq
                        className={!showAllQuestions && index >= VISIBLE_QUESTIONS_COUNT ? "max-md:hidden" : undefined}
                        headingElement="p"
                        invert={props.invert}
                        isExpanded={item.text ? activeItemIndex === `${props.id}-faq1-${index}` : false}
                        key={item.id}
                        onClick={handleFaqClick(item)}
                        onToggle={onToggle(item, `${props.id}-faq1-${index}`)}
                        role="listitem"
                        text={item.text}
                        title={item.title}
                    />
                ))}
            </div>
            <Show when={!showAllQuestions && totalQuestionsCount > VISIBLE_QUESTIONS_COUNT}>
                <div className="mt-9 text-center md:hidden">
                    <ShowMoreButtonComponent
                        $fontSize={[14, null, 16]}
                        $height={BUTTONS.small}
                        $py={8}
                        $textPr={12}
                        $textTransform="uppercase"
                        endIcon={<Icon className="text-green" name="arrowRightCircleOutline" size={20} />}
                        onClick={loadAllHandler}
                    >
                        Zobrazit více
                    </ShowMoreButtonComponent>
                </div>
            </Show>
        </div>
    );
});

FaqAccordion.displayName = "FaqAccordion";
