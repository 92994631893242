import { ArticlePreviewFragment } from "@gql-schema";
import { AnalyticsService } from "@shared/services/AnalyticsService";
import { UrlHelper } from "@shared/utils/UrlHelper";
import { BlogItem } from "@ui/components/BlogItem/BlogItem";
import { ButtonGreen } from "@ui/components/Button/ButtonGreen";
import { ButtonOutlinedGreen } from "@ui/components/Button/ButtonOutlinedGreen";
import { Icon } from "@ui/components/Icon/Icon";
import { BUTTONS } from "@ui/theme/buttons";
import { Show } from "@uxf/core/components/show";
import { ReactNode } from "react";

interface Props {
    blogArticles?: ArticlePreviewFragment[];
    buttonText?: string;
    className?: string;
    showButton?: boolean;
    title?: ReactNode;
}

export function BlogBox(props: Props) {
    const buttonText = props.buttonText ?? "Všechny novinky";

    return (
        <section className={`pb-10 pt-7 max-md:px-6 md:pb-14 md:pt-16 lg:pb-24 lg:pt-18 ${props.className ?? ""}`}>
            <h2 className="ui-typo-h2 text-center text-green">{props.title ?? <>Novinky z&nbsp;Kalkulátor.cz</>}</h2>
            <div
                className="container mt-11 max-md:space-y-7 md:mt-14 md:grid md:grid-cols-2 md:gap-7.5 lg:mt-17 lg:grid-cols-3 lg:gap-12"
                role="list"
            >
                {props.blogArticles?.map((article, index) => (
                    <BlogItem
                        analyticsCallback={AnalyticsService.uiEvent("Button", "Click", "HP_Clanky")}
                        className={index === 2 ? "md:max-lg:hidden" : undefined}
                        image={article.image ? UrlHelper.getImageUrl(article.image, 300, 200) : undefined}
                        key={article.id}
                        params={{ articleId: article.id, slug: article.seoName }}
                        perex={article.perex || ""}
                        route="blog-zone/article"
                        title={article.title || ""}
                    />
                ))}
            </div>
            <Show when={props.showButton ?? true}>
                <div className="mt-13 flex min-w-0 justify-center md:mt-20">
                    <div className="min-w-0 max-md:hidden">
                        <ButtonGreen route="blog-zone/news" $px={56}>
                            {buttonText}
                        </ButtonGreen>
                    </div>
                    <div className="min-w-0 md:hidden">
                        <ButtonOutlinedGreen
                            $height={BUTTONS.medium}
                            endIcon={<Icon name="arrowRightCircleOutline" size={24} $ml={24} />}
                            route="blog-zone/news"
                        >
                            {buttonText}
                        </ButtonOutlinedGreen>
                    </div>
                </div>
            </Show>
        </section>
    );
}
