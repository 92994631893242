import { cx } from "@uxf/core/utils/cx";
import { memo } from "react";
import { WAVE_SHAPE } from "./WaveShape";

interface Props {
    color: "green" | "yellow";
    type: "top" | "bottom";
}

export const Wave = memo<Props>((props) => (
    <div className={`flex ${props.color === "green" ? "text-green" : "text-yellow"} h-10 md:hidden`}>
        <div className={cx(props.type !== "top" && "bg-current", props.type === "top" ? "w-[200%]" : "w-full")} />
        <div className={`flex shrink-0 ${props.type === "top" ? "-scale-x-100" : "-scale-y-100"} w-[140px]`}>
            {WAVE_SHAPE}
        </div>
        <div className={cx(props.type === "top" && "bg-current", props.type === "top" ? "w-full" : "w-[200%]")} />
    </div>
));

Wave.displayName = "Wave";
