import { Icon } from "@ui/components/Icon/Icon";
import { Show } from "@uxf/core/components/show";
import { cx } from "@uxf/core/utils/cx";
import { HTMLAttributes, MouseEventHandler, ReactNode } from "react";
import { Collapsible, CollapsibleProps } from "./collapsible";

const DEFAULT_COLORS = {
    title: "text-green",
    text: "text-green",
    border: "border-green",
    focus: "[--focus-color:theme(colors.green.DEFAULT/0.25)]",
};

const INVERT_COLORS = {
    title: "text-white",
    text: "text-white",
    border: "border-white",
    focus: "[--focus-color:theme(colors.white/0.25)]",
};

export interface CollapsibleFaqProps extends CollapsibleProps {
    analyticsCallback?: () => void;
    className?: string;
    disableScrollOnExpanded?: boolean;
    headingElement?: keyof Pick<JSX.IntrinsicElements, "h2" | "h3" | "h4" | "h5" | "h6" | "p">;
    invert?: boolean;
    isExpanded?: boolean;
    onClick?: MouseEventHandler;
    onToggle?: () => void;
    role?: HTMLAttributes<HTMLDivElement>["role"];
    text?: ReactNode;
    title: ReactNode;
}

export function CollapsibleFaq(props: CollapsibleFaqProps) {
    const colors = props.invert ? INVERT_COLORS : DEFAULT_COLORS;

    const HeadingElement = props.headingElement ?? "p";

    return (
        <Collapsible
            analyticsCallback={props.analyticsCallback}
            className={`-mt-px break-inside-avoid border-y py-1 ${colors.border} ${props.className ?? ""}`}
            classNameFocusColor={colors.focus}
            disableScrollOnExpanded={!props.text}
            isExpanded={props.isExpanded}
            onClick={props.onClick}
            onToggle={props.onToggle}
            role={props.role}
        >
            <div className="flex min-h-15 min-w-0">
                <div className="flex min-w-0 grow items-center py-2">
                    <HeadingElement className={`ui-typo-body font-bold ${colors.title}`}>{props.title}</HeadingElement>
                </div>
                <div className="mx-3 flex w-9 min-w-0 shrink-0 items-center">
                    <Icon
                        className={cx(
                            "w-9 overflow-hidden text-green transition-transform",
                            props.isExpanded && "rotate-90",
                        )}
                        name={props.invert ? "arrowRightCircleFilledYellow" : "arrowRightCircleFilled"}
                    />
                </div>
            </div>
            <Show when={Boolean(props.text)}>
                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
                <div
                    className={cx(!props.isExpanded && "sr-only", "mb-4 cursor-default max-md:text-12", colors.text)}
                    onClick={(e) => e.stopPropagation()}
                >
                    {props.text}
                </div>
            </Show>
        </Collapsible>
    );
}
