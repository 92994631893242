import { HeadingRenderer } from "@ui/components/Markdown/Renderers";
import { FC, PropsWithChildren, Ref } from "react";
import ReactMarkdown from "react-markdown";
import { FlattenSimpleInterpolation } from "styled-components";
import { Wrapper } from "./styles/Wrapper";

export interface MarkdownProps {
    className?: string;
    overrideStyle?: FlattenSimpleInterpolation;
    source?: string | null;
    wrapperRef?: Ref<HTMLDivElement>;
}

export const Markdown: FC<PropsWithChildren<MarkdownProps>> = (props) => (
    <Wrapper className={`ui-markdown ${props.className ?? ""}`} $css={props.overrideStyle} ref={props.wrapperRef}>
        <ReactMarkdown
            components={{
                h1: HeadingRenderer,
                h2: HeadingRenderer,
                h3: HeadingRenderer,
                h4: HeadingRenderer,
                h5: HeadingRenderer,
                h6: HeadingRenderer,
            }}
        >
            {props.source || ""}
        </ReactMarkdown>
    </Wrapper>
);
