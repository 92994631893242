import { FlexBox, FlexBoxProps } from "@ui/components/FlexBox/FlexBox";
import { BREAKPOINTS } from "@ui/theme/breakpoints";
import { aspectRatio } from "@uxf/styles/mixins/aspectRatio";
import { ObjectFitting, objectFitting } from "@uxf/styles/properties/objectFitting";
import { ResponsiveProperty } from "@uxf/styles/types";
import styled, { css } from "styled-components";

export interface ImageRootProps extends Omit<FlexBoxProps, "$position">, ObjectFitting {
    $absolute?: boolean;
    $aspectRatio?: ResponsiveProperty.AspectRatio;
}

export const ImageRoot = styled(FlexBox)<ImageRootProps>(
    ({ $absolute, $aspectRatio, $objectFit, $objectPosition }) => css`
        position: ${$absolute ? "absolute" : "relative"};

        ${$aspectRatio ? aspectRatio(BREAKPOINTS, $aspectRatio) : null};

        /* stylelint-disable-next-line selector-class-pattern */
        .ui-image__element {
            ${objectFitting({
                breakpoints: BREAKPOINTS,
                $objectFit,
                $objectPosition,
            })}
        }
    `,
);
