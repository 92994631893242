/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { SearchResultFragmentDoc, ArticlePreviewFragmentDoc, ArticleCategoryFragmentDoc, ArticleFragmentDoc, ArticleTagFragmentDoc } from './BlogFragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const BlogSearchArticlesDocument = gql`
    query BlogSearchArticles($searchTerm: String!) {
  blogSearchArticles(searchTerm: $searchTerm) {
    ...SearchResult
  }
}
    ${SearchResultFragmentDoc}`;

/**
 * __useBlogSearchArticlesQuery__
 *
 * To run a query within a React component, call `useBlogSearchArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogSearchArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogSearchArticlesQuery({
 *   variables: {
 *      searchTerm: // value for 'searchTerm'
 *   },
 * });
 */
export function useBlogSearchArticlesQuery(baseOptions: Apollo.QueryHookOptions<Types.BlogSearchArticlesQuery, Types.BlogSearchArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BlogSearchArticlesQuery, Types.BlogSearchArticlesQueryVariables>(BlogSearchArticlesDocument, options);
      }
export function useBlogSearchArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BlogSearchArticlesQuery, Types.BlogSearchArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BlogSearchArticlesQuery, Types.BlogSearchArticlesQueryVariables>(BlogSearchArticlesDocument, options);
        }
export type BlogSearchArticlesQueryHookResult = ReturnType<typeof useBlogSearchArticlesQuery>;
export type BlogSearchArticlesLazyQueryHookResult = ReturnType<typeof useBlogSearchArticlesLazyQuery>;
export type BlogSearchArticlesQueryResult = Apollo.QueryResult<Types.BlogSearchArticlesQuery, Types.BlogSearchArticlesQueryVariables>;
export const GetBlogCategoryDocument = gql`
    query GetBlogCategory($categoryId: Int!, $offset: Int, $limit: Int) {
  blogCategory(categoryId: $categoryId, offset: $offset, limit: $limit) {
    ...ArticleCategory
  }
}
    ${ArticleCategoryFragmentDoc}`;

/**
 * __useGetBlogCategoryQuery__
 *
 * To run a query within a React component, call `useGetBlogCategoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogCategoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogCategoryQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBlogCategoryQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBlogCategoryQuery, Types.GetBlogCategoryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBlogCategoryQuery, Types.GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
      }
export function useGetBlogCategoryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBlogCategoryQuery, Types.GetBlogCategoryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBlogCategoryQuery, Types.GetBlogCategoryQueryVariables>(GetBlogCategoryDocument, options);
        }
export type GetBlogCategoryQueryHookResult = ReturnType<typeof useGetBlogCategoryQuery>;
export type GetBlogCategoryLazyQueryHookResult = ReturnType<typeof useGetBlogCategoryLazyQuery>;
export type GetBlogCategoryQueryResult = Apollo.QueryResult<Types.GetBlogCategoryQuery, Types.GetBlogCategoryQueryVariables>;
export const GetBlogArticleDocument = gql`
    query GetBlogArticle($articleId: Int!) {
  blogArticle(articleId: $articleId) {
    ...Article
  }
}
    ${ArticleFragmentDoc}`;

/**
 * __useGetBlogArticleQuery__
 *
 * To run a query within a React component, call `useGetBlogArticleQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogArticleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogArticleQuery({
 *   variables: {
 *      articleId: // value for 'articleId'
 *   },
 * });
 */
export function useGetBlogArticleQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBlogArticleQuery, Types.GetBlogArticleQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBlogArticleQuery, Types.GetBlogArticleQueryVariables>(GetBlogArticleDocument, options);
      }
export function useGetBlogArticleLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBlogArticleQuery, Types.GetBlogArticleQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBlogArticleQuery, Types.GetBlogArticleQueryVariables>(GetBlogArticleDocument, options);
        }
export type GetBlogArticleQueryHookResult = ReturnType<typeof useGetBlogArticleQuery>;
export type GetBlogArticleLazyQueryHookResult = ReturnType<typeof useGetBlogArticleLazyQuery>;
export type GetBlogArticleQueryResult = Apollo.QueryResult<Types.GetBlogArticleQuery, Types.GetBlogArticleQueryVariables>;
export const GetBlogArticlesDocument = gql`
    query GetBlogArticles($offset: Int, $limit: Int) {
  blogArticles(offset: $offset, limit: $limit) {
    ...ArticlePreview
  }
}
    ${ArticlePreviewFragmentDoc}`;

/**
 * __useGetBlogArticlesQuery__
 *
 * To run a query within a React component, call `useGetBlogArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogArticlesQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useGetBlogArticlesQuery(baseOptions?: Apollo.QueryHookOptions<Types.GetBlogArticlesQuery, Types.GetBlogArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBlogArticlesQuery, Types.GetBlogArticlesQueryVariables>(GetBlogArticlesDocument, options);
      }
export function useGetBlogArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBlogArticlesQuery, Types.GetBlogArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBlogArticlesQuery, Types.GetBlogArticlesQueryVariables>(GetBlogArticlesDocument, options);
        }
export type GetBlogArticlesQueryHookResult = ReturnType<typeof useGetBlogArticlesQuery>;
export type GetBlogArticlesLazyQueryHookResult = ReturnType<typeof useGetBlogArticlesLazyQuery>;
export type GetBlogArticlesQueryResult = Apollo.QueryResult<Types.GetBlogArticlesQuery, Types.GetBlogArticlesQueryVariables>;
export const BlogArticlesByTagsDocument = gql`
    query BlogArticlesByTags($tagIds: [Int!]!, $excludedArticles: [Int!]!, $offset: Int!, $limit: Int!) {
  blogArticles: blogArticlesByTags(
    tagIds: $tagIds
    excludedArticles: $excludedArticles
    offset: $offset
    limit: $limit
  ) {
    ...ArticlePreview
  }
}
    ${ArticlePreviewFragmentDoc}`;

/**
 * __useBlogArticlesByTagsQuery__
 *
 * To run a query within a React component, call `useBlogArticlesByTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogArticlesByTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogArticlesByTagsQuery({
 *   variables: {
 *      tagIds: // value for 'tagIds'
 *      excludedArticles: // value for 'excludedArticles'
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useBlogArticlesByTagsQuery(baseOptions: Apollo.QueryHookOptions<Types.BlogArticlesByTagsQuery, Types.BlogArticlesByTagsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BlogArticlesByTagsQuery, Types.BlogArticlesByTagsQueryVariables>(BlogArticlesByTagsDocument, options);
      }
export function useBlogArticlesByTagsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BlogArticlesByTagsQuery, Types.BlogArticlesByTagsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BlogArticlesByTagsQuery, Types.BlogArticlesByTagsQueryVariables>(BlogArticlesByTagsDocument, options);
        }
export type BlogArticlesByTagsQueryHookResult = ReturnType<typeof useBlogArticlesByTagsQuery>;
export type BlogArticlesByTagsLazyQueryHookResult = ReturnType<typeof useBlogArticlesByTagsLazyQuery>;
export type BlogArticlesByTagsQueryResult = Apollo.QueryResult<Types.BlogArticlesByTagsQuery, Types.BlogArticlesByTagsQueryVariables>;
export const BlogMostReadArticlesDocument = gql`
    query BlogMostReadArticles($categoryId: Int) {
  blogMostReadArticles(categoryId: $categoryId) {
    ...ArticlePreview
  }
}
    ${ArticlePreviewFragmentDoc}`;

/**
 * __useBlogMostReadArticlesQuery__
 *
 * To run a query within a React component, call `useBlogMostReadArticlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogMostReadArticlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogMostReadArticlesQuery({
 *   variables: {
 *      categoryId: // value for 'categoryId'
 *   },
 * });
 */
export function useBlogMostReadArticlesQuery(baseOptions?: Apollo.QueryHookOptions<Types.BlogMostReadArticlesQuery, Types.BlogMostReadArticlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BlogMostReadArticlesQuery, Types.BlogMostReadArticlesQueryVariables>(BlogMostReadArticlesDocument, options);
      }
export function useBlogMostReadArticlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BlogMostReadArticlesQuery, Types.BlogMostReadArticlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BlogMostReadArticlesQuery, Types.BlogMostReadArticlesQueryVariables>(BlogMostReadArticlesDocument, options);
        }
export type BlogMostReadArticlesQueryHookResult = ReturnType<typeof useBlogMostReadArticlesQuery>;
export type BlogMostReadArticlesLazyQueryHookResult = ReturnType<typeof useBlogMostReadArticlesLazyQuery>;
export type BlogMostReadArticlesQueryResult = Apollo.QueryResult<Types.BlogMostReadArticlesQuery, Types.BlogMostReadArticlesQueryVariables>;
export const BlogHomepageDocument = gql`
    query BlogHomepage {
  all: blogArticles {
    ...ArticlePreview
  }
  insurance: blogCategory(categoryId: 1) {
    ...ArticleCategory
  }
  energy: blogCategory(categoryId: 2) {
    ...ArticleCategory
  }
  travelInsurance: blogCategory(categoryId: 3) {
    ...ArticleCategory
  }
  finance: blogCategory(categoryId: 5) {
    ...ArticleCategory
  }
  law: blogCategory(categoryId: 6) {
    ...ArticleCategory
  }
}
    ${ArticlePreviewFragmentDoc}
${ArticleCategoryFragmentDoc}`;

/**
 * __useBlogHomepageQuery__
 *
 * To run a query within a React component, call `useBlogHomepageQuery` and pass it any options that fit your needs.
 * When your component renders, `useBlogHomepageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBlogHomepageQuery({
 *   variables: {
 *   },
 * });
 */
export function useBlogHomepageQuery(baseOptions?: Apollo.QueryHookOptions<Types.BlogHomepageQuery, Types.BlogHomepageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.BlogHomepageQuery, Types.BlogHomepageQueryVariables>(BlogHomepageDocument, options);
      }
export function useBlogHomepageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.BlogHomepageQuery, Types.BlogHomepageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.BlogHomepageQuery, Types.BlogHomepageQueryVariables>(BlogHomepageDocument, options);
        }
export type BlogHomepageQueryHookResult = ReturnType<typeof useBlogHomepageQuery>;
export type BlogHomepageLazyQueryHookResult = ReturnType<typeof useBlogHomepageLazyQuery>;
export type BlogHomepageQueryResult = Apollo.QueryResult<Types.BlogHomepageQuery, Types.BlogHomepageQueryVariables>;
export const GetBlogTagDocument = gql`
    query GetBlogTag($tagId: Int!) {
  blogTag(tagId: $tagId) {
    ...ArticleTag
  }
}
    ${ArticleTagFragmentDoc}`;

/**
 * __useGetBlogTagQuery__
 *
 * To run a query within a React component, call `useGetBlogTagQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBlogTagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBlogTagQuery({
 *   variables: {
 *      tagId: // value for 'tagId'
 *   },
 * });
 */
export function useGetBlogTagQuery(baseOptions: Apollo.QueryHookOptions<Types.GetBlogTagQuery, Types.GetBlogTagQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.GetBlogTagQuery, Types.GetBlogTagQueryVariables>(GetBlogTagDocument, options);
      }
export function useGetBlogTagLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.GetBlogTagQuery, Types.GetBlogTagQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.GetBlogTagQuery, Types.GetBlogTagQueryVariables>(GetBlogTagDocument, options);
        }
export type GetBlogTagQueryHookResult = ReturnType<typeof useGetBlogTagQuery>;
export type GetBlogTagLazyQueryHookResult = ReturnType<typeof useGetBlogTagLazyQuery>;
export type GetBlogTagQueryResult = Apollo.QueryResult<Types.GetBlogTagQuery, Types.GetBlogTagQueryVariables>;