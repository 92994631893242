import { Clickable, ClickableProps } from "@ui/components/Clickable/Clickable";
import { Image } from "@ui/components/Image/Image";
import { WAVE_SHAPE } from "@ui/components/Wave/WaveShape";
import { Hide } from "@uxf/core/components/hide";
import { isEmpty } from "@uxf/core/utils/is-empty";
import { ReactNode } from "react";

function BlogItemShapes() {
    return (
        <div className="pointer-events-none relative z-1 min-w-0 md:z-0">
            <div className="absolute -top-4 flex h-4 w-full min-w-0 text-white max-md:hidden lg:-top-8 lg:h-8">
                <div className="h-full min-w-0 basis-20 bg-current" />
                <div className="flex min-w-0 basis-12">{WAVE_SHAPE}</div>
                <div className="min-w-0 grow" />
            </div>
            <div className="absolute -left-6 flex h-full w-6 min-w-0 text-neutral-100 md:-left-8 md:hidden md:w-8">
                <svg className="size-full" preserveAspectRatio="none" viewBox="0 0 20 20">
                    <polygon fill="currentColor" points={`${20},0 0,${20} ${20},${20}`} />
                </svg>
            </div>
        </div>
    );
}

interface Props extends Pick<ClickableProps, "analyticsCallback" | "params" | "route"> {
    className?: string;
    image?: string | null;
    perex: ReactNode;
    title: string;
}

export function BlogItem(props: Props) {
    return (
        <div
            className={`relative flex rounded-[30px] bg-neutral-100 text-green no-underline shadow-shadow2 md:rounded-[40px] md:bg-white lg:[&:nth-of-type(2)]:scale-110 ${props.className ?? ""}`}
            role="listitem"
        >
            <div className="flex w-full min-w-0 overflow-hidden rounded-inherit md:flex-col">
                <Image
                    className="h-24 w-[30%] shrink-0 bg-white md:h-[200px] md:w-full"
                    imgProps={{ alt: props.title }}
                    src={props.image}
                />
                <BlogItemShapes />
                <div className="flex min-w-0 flex-col justify-center py-2 pl-2 pr-6 md:justify-start md:pb-9 md:pl-6 md:pt-6">
                    <Hide when={isEmpty(props.title)}>
                        <div className="md:min-h-11">
                            <h2 className="ui-typo-body2 line-clamp-2 font-semibold underline max-md:text-12 md:font-bold">
                                {props.title}
                            </h2>
                        </div>
                    </Hide>
                    <div className="mt-6 flex min-w-0 max-md:hidden">
                        <p className="ui-typo-small line-clamp-3 font-normal">{props.perex}</p>
                    </div>
                </div>
            </div>
            <Clickable
                className="absolute inset-0 rounded-inherit no-underline outline-none [--focus-color:theme(colors.green.DEFAULT/0.25)] focus-visible:shadow-focus"
                params={props.params}
                route={props.route}
            >
                <span className="sr-only">Přejít na detail článku</span>
            </Clickable>
        </div>
    );
}
