import { Box, BoxProps } from "@ui/components/Box/Box";
import { Link } from "@ui/components/Link/Link";
import { Markdown } from "@ui/components/Markdown/Markdown";
import { COLORS } from "@ui/theme/colors";
import { TYPOGRAPHY } from "@ui/theme/typography";
import { slugify } from "@uxf/core/utils/slugify";
import { FC, PropsWithChildren, memo } from "react";

interface Props extends BoxProps {
    faqId: number;
    invert?: boolean;
    seoName: string;
    text: string;
}

const Component: FC<PropsWithChildren<Props>> = (props) => {
    const { text, faqId, seoName, invert, ...restProps } = props;

    return (
        <Box {...restProps}>
            <Markdown source={text} />{" "}
            <Link
                $color={invert ? COLORS.white : undefined}
                $fontWeight={TYPOGRAPHY.fontWeight.bold}
                $hoverColor={invert ? COLORS.green.secondaryDark : undefined}
                params={{ articleId: faqId, slug: slugify(seoName || "") }}
                route="knowledgebase-zone/detail"
            >
                Zobrazit více
            </Link>
        </Box>
    );
};

export const CollapsibleFaqContent = memo(Component);

CollapsibleFaqContent.displayName = "CollapsibleFaqContent";
