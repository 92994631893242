import { FaqAccordion, FaqAccordionItemData } from "@ui/components/faq-accordion/faq-accordion";
import { isEmpty } from "@uxf/core/utils/is-empty";
import { ReactNode, memo } from "react";

interface Props {
    data: FaqAccordionItemData[];
    invert?: boolean;
    onClickRedirect?: (item: FaqAccordionItemData) => void;
    title?: ReactNode;
}

const DEFAULT_COLORS = {
    background: "bg-white",
    title: "text-green",
};

const INVERTED_COLORS = {
    background: "bg-green",
    title: "text-yellow",
};

export const FaqSection = memo<Props>((props) => {
    if (isEmpty(props.data)) {
        return null;
    }

    const colors = props.invert ? INVERTED_COLORS : DEFAULT_COLORS;

    return (
        <div className={`min-w-0 ${colors.background}`}>
            <section className="container pb-8 pt-9 max-md:px-6 md:pb-14 md:pt-11 lg:pb-32 lg:pt-17">
                <h2 className={`ui-typo-h2 text-center ${colors.title}`}>{props.title ?? "Časté dotazy"}</h2>
                <FaqAccordion
                    className="mt-9 md:mt-17 lg:mt-20"
                    data={props.data}
                    invert={props.invert}
                    onClickRedirect={props.onClickRedirect}
                />
            </section>
        </div>
    );
});

FaqSection.displayName = "FaqSection";
