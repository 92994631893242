import { ContactSection } from "@shared/components/ContactSection/ContactSection";
import { Footer } from "@shared/components/Footer/Footer";
import { FC, PropsWithChildren } from "react";
import { LayoutBase, LayoutBaseProps } from "./LayoutBase";

export interface LayoutProps extends LayoutBaseProps {
    hideContactSection?: boolean;
}

export const Layout: FC<PropsWithChildren<LayoutProps>> = (props) => {
    const { children, hideContactSection, ...layoutProps } = props;

    return (
        <LayoutBase {...layoutProps}>
            {children}
            {!hideContactSection && <ContactSection />}
            <Footer />
        </LayoutBase>
    );
};
