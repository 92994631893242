/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
import { NavbarConfigFragmentDoc } from './NavbarFragments.generated';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;

export const NavbarConfigDocument = gql`
    query NavbarConfig {
  navbarConfig {
    ...NavbarConfig
  }
}
    ${NavbarConfigFragmentDoc}`;

/**
 * __useNavbarConfigQuery__
 *
 * To run a query within a React component, call `useNavbarConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useNavbarConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNavbarConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useNavbarConfigQuery(baseOptions?: Apollo.QueryHookOptions<Types.NavbarConfigQuery, Types.NavbarConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<Types.NavbarConfigQuery, Types.NavbarConfigQueryVariables>(NavbarConfigDocument, options);
      }
export function useNavbarConfigLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<Types.NavbarConfigQuery, Types.NavbarConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<Types.NavbarConfigQuery, Types.NavbarConfigQueryVariables>(NavbarConfigDocument, options);
        }
export type NavbarConfigQueryHookResult = ReturnType<typeof useNavbarConfigQuery>;
export type NavbarConfigLazyQueryHookResult = ReturnType<typeof useNavbarConfigLazyQuery>;
export type NavbarConfigQueryResult = Apollo.QueryResult<Types.NavbarConfigQuery, Types.NavbarConfigQueryVariables>;