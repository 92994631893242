import { Icon } from "@ui/components/Icon/Icon";
import { SnapCarousel } from "@ui/components/SnapCarousel/SnapCarousel";
import { COLORS } from "@ui/theme/colors";
import { CSSProperties, FC, HTMLAttributes, PropsWithChildren, ReactNode, memo } from "react";

const data = [
    {
        id: 1,
        icon: <Icon name="documentWriting" color={COLORS.green.default} size={130} />,
        title: <>Postaráme se&nbsp;o&nbsp;vás dlouhodobě</>,
        text: (
            <>
                Vyřídíme pro&nbsp;vás&nbsp;přepis energií, včas upozorníme, kdy&nbsp;vám&nbsp;končí smlouva, nebo
                na&nbsp;jaký bonus k&nbsp;pojistce máte nárok. Nejsme na&nbsp;jedno použití. Na&nbsp;nás se&nbsp;můžete
                spolehnout.
            </>
        ),
    },
    {
        id: 2,
        icon: <Icon name="piggyBank" color={COLORS.green.default} size={160} />,
        title: <>Naše služby jsou&nbsp;zdarma</>,
        text: (
            <>
                Naše služby jsou pro&nbsp;vás zcela zdarma, odměnou nám jsou provize od&nbsp;dodavatelů energií
                a&nbsp;pojišťoven. I&nbsp;tak vám garantujeme nejlepší ceny na&nbsp;trhu.
            </>
        ),
    },
    {
        id: 3,
        icon: <Icon name="secured" color={COLORS.green.default} size={150} />,
        title: <>Bez&nbsp;rizika a&nbsp;otravných telefonátů</>,
        text: (
            <>
                S&nbsp;námi neriskujete. Vaše osobní údaje využíváme jen k&nbsp;upřesnění nabídky a&nbsp;k&nbsp;případné
                přípravě smlouvy. Volat vám budeme jen&nbsp;tehdy, pokud k&nbsp;tomu svolíte a&nbsp;naše
                služby&nbsp;uvítáte.
            </>
        ),
    },
    {
        id: 4,
        icon: <Icon name="wolf" color={COLORS.green.default} size={150} />,
        title: <>Hlídací vlk pro vaše klidné&nbsp;noci</>,
        text: (
            <>
                Díky naší službě Hlídací vlk&nbsp;už&nbsp;nepropásnete žádné důležité termíny. Nastavíme, kdy&nbsp;vám
                končí úvazek u&nbsp;dodavatele energií nebo pojišťovny a&nbsp;včas se&nbsp;vám&nbsp;ozveme
                s&nbsp;výhodnými nabídkami. Naše služby jsou dlouhodobé.
            </>
        ),
    },
];

interface SlideProps {
    icon: ReactNode;
    role?: HTMLAttributes<HTMLDivElement>["role"];
    text: ReactNode;
    title: ReactNode;
}

const Slide: FC<PropsWithChildren<SlideProps>> = (props) => (
    <div className="flex h-full items-stretch text-black" role={props.role}>
        <div className="user-select-none z-focus rounded-xl border border-neutral-200 px-6 pb-8 pt-12 transition-[border-color] hover:text-green">
            <div className="flex h-[150px] items-center justify-center">{props.icon}</div>
            <p className="ui-typo-h4 mt-6 text-green" role="heading" aria-level={3}>
                {props.title}
            </p>
            <p className="ui-typo-body mt-6">{props.text}</p>
        </div>
    </div>
);

export interface HomepageCarouselProps {
    className?: string;
    id?: string;
}

export const HomepageCarousel = memo<HomepageCarouselProps>((props) => (
    <section className={`container py-8 md:py-11 lg:py-19 ${props.className ?? ""}`} id={props.id}>
        <h2 className="ui-typo-h2 mb-8 text-center text-green max-md:px-6 md:mb-11 lg:mb-18.5">
            My počítáme, vy šetříte.
        </h2>
        <SnapCarousel
            className="relative -mt-6 md:hidden"
            controlsBackgroundHoverColor={COLORS.neutral["100"]}
            controlsColor={COLORS.green.default}
            hideDots
            showDisabledControls
        >
            {data.map((item) => (
                <Slide icon={item.icon} key={item.id} text={item.text} title={item.title} />
            ))}
        </SnapCarousel>
        <div
            className="hidden grid-cols-[repeat(calc(var(--column-count)/2),_1fr)] gap-8 md:grid lg:grid-cols-[repeat(var(--column-count),_1fr)]"
            role="list"
            style={
                {
                    "--column-count": data.length,
                } as Partial<CSSProperties>
            }
        >
            {data.map((item) => (
                <Slide icon={item.icon} key={item.id} role="listitem" text={item.text} title={item.title} />
            ))}
        </div>
    </section>
));

HomepageCarousel.displayName = "HomepageCarousel";
