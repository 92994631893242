/* eslint-disable */
/* tslint:disable */
import * as Types from '../generated';

import { gql } from '@apollo/client';
export const NavbarConfigItemFragmentDoc = gql`
    fragment NavbarConfigItem on NavbarConfigItem {
  label
  href
}
    `;
export const NavbarConfigSectionColumnFragmentDoc = gql`
    fragment NavbarConfigSectionColumn on NavbarConfigSectionColumn {
  items {
    ...NavbarConfigItem
  }
}
    ${NavbarConfigItemFragmentDoc}`;
export const NavbarConfigSectionFragmentDoc = gql`
    fragment NavbarConfigSection on NavbarConfigSection {
  label
  href
  columns {
    ...NavbarConfigSectionColumn
  }
}
    ${NavbarConfigSectionColumnFragmentDoc}`;
export const NavbarConfigFragmentDoc = gql`
    fragment NavbarConfig on NavbarConfig {
  sections {
    ...NavbarConfigSection
  }
}
    ${NavbarConfigSectionFragmentDoc}`;