import { COLORS } from "@ui/theme/colors";
import { forwardRef, ForwardRefRenderFunction } from "react";
import { Button, ButtonProps } from "./Button";

type ButtonWhiteProps = Omit<ButtonProps, "$outline" | "$outlineBackgroundColor" | "$outlineBackgroundHoverColor">;

const ButtonWhiteComponent: ForwardRefRenderFunction<HTMLAnchorElement, ButtonWhiteProps> = (props, ref) => (
    <Button
        $color={COLORS.white}
        $focusColor={COLORS.white}
        $hoverColor={COLORS.yellow.default}
        $textColor={COLORS.green.default}
        $textTransform="uppercase"
        $underline
        ref={ref}
        {...props}
    />
);

export const ButtonWhite = forwardRef(ButtonWhiteComponent);

ButtonWhite.displayName = "ButtonWhite";
